import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';

import CountDownTimer from './CountdownTimer';

import './Recruit.css';

const images = [
  { original: 'https://i.imgur.com/M7Wkmy6.jpg' },
  { original: 'https://i.imgur.com/zCHX7E1.jpg' },
  { original: 'https://i.imgur.com/FipatAz.jpg' },
  { original: 'https://i.imgur.com/L5xyAGn.jpg' },
  { original: 'https://i.imgur.com/Qu1sncM.jpg' },
  { original: 'https://i.imgur.com/bAT8AWL.jpg' },
  { original: 'https://i.imgur.com/9XIayiI.jpg' },
  { original: 'https://i.imgur.com/71XgG6k.jpg' },
  { original: 'https://i.imgur.com/3JuwvTc.jpg' },
  { original: 'https://i.imgur.com/BIpKIHF.jpg' },
  { original: 'https://i.imgur.com/bYlcyhN.jpg' },
  { original: 'https://i.imgur.com/F5ZFG4n.jpg' },
  { original: 'https://i.imgur.com/MehXCKQ.jpg' },
  { original: 'https://i.imgur.com/PaFg4LM.jpg' },
];

const Recruit = () => {




  return (
  <>
    <Navbar />
    <div className="hero-section">
      <h1>Hi!</h1>
      <h2>Looking for your next big thing?</h2>
      {/*<h3>Está atento às nossas redes sociais, terás novidades em breve</h3>*/}
      <a href="https://forms.gle/AMSvyHWgaeVkw2hM9" target="_blank">jeK is recruiting!</a>
    </div>
    {/*<section className="c-recruit">
      <ImageGallery
        items={images}
        showBullets={true}
        showThumbnails={false}
        loading={true}
        infinite={true}
        lazyLoad={true}
        showPlayButton={false}
        originalHeight={false}
      />
    </section>
    <CountDownTimer date="October 21, 2023 00:00:00" />*/}
    <Footer />
  </>
  )
}

export default Recruit;
