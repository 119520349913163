import { Link } from 'react-router-dom';

import './Footer.css';

const Footer = (src) => (
  <footer>
    <div className="footer-address">
      <div>
        <a href="https://www.facebook.com/jeknowledge">
          <img className="social" src="/Images/facebook.svg" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/jeknowledge/">
          <img className="social" src="/Images/instagram.svg" alt="instagram" />
        </a>
        <a href="https://www.linkedin.com/company/jeknowledge/">
          <img className="social" src="/Images/linkedin.svg" alt="linkedin" />
        </a>
      </div>
      <div>
        <span>jeKnowledge Associação</span>
        <span>Rua Larga</span>
        <span>Departamento de Física, B3</span>
        <span>P-3004-516 Coimbra</span>
      </div>
    </div>
    <div className="footer-middle">
      <span className="b-title title is-2">Still not convinced?</span>
      <span className="subtitle s-text is-white is-4">Click this button.</span>
      <Link className="button is-info" to="/hello">
        Contact us!
      </Link>
    </div>
    <div className="footer-contacts">
      <img src="/Images/logo-text-white.svg" alt="White Logo jeK" />
      <span>geral@jeknowledge.com</span>
      <span> +351 913 198 301</span>
      <span>This website is</span>
      <span>© jeKnowledge 2021.</span>
    </div>
  </footer>
  
);

export default Footer;
